import { createApp } from 'vue';
import VueCarousel from '@/vue/VueCarousel.vue';
import VueCarouselBanner from '@/vue/VueCarouselBanner.vue';

// App main
const main = async () => {
    // Create our vue instance
    const bannerApp = createApp({
      components: {
        VueCarouselBanner
      }
    });

    // Mount the app
    const bannerAppMounted = bannerApp.mount('#banner-carousel-container');

    // Create our vue instance
    const newsApp = createApp({
      components: {
        VueCarousel
      }
    });

    // Mount the app
    const newsAppMountd = newsApp.mount('#news-carousel-container');

    return Promise.all([
      bannerAppMounted,
      newsAppMountd,
    ])
};

// Execute async function
main().then(() => {
  // console.log('loaded carousel.js');
});
