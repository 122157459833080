<template>
  <div>
    <Carousel
      :settings="settings"
      :breakpoints="breakpoints"
      :autoplay="5000"
      :wrap-around="true"
    >
      <Slide
        v-for="entry in entries"
        :key="entry"
      >
        <div class="carousel__item">
          <img :src="entry">
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
  import 'vue3-carousel/dist/carousel.css';
  import { defineComponent } from 'vue';
  import { Carousel, Slide } from 'vue3-carousel';

  export default defineComponent({
    name: 'Vue3Carousel',
    components: {
      Carousel,
      Slide,
    },
    props: {
      entries: {
        type: Array,
        default: () => [
          '/assets/site/images/backgrounds/banner-pears.jpg',
          '/assets/site/images/backgrounds/banner-spinach.jpg',
          '/assets/site/images/backgrounds/banner-apples.jpg'
        ],
      },
    },

    data: () => ({
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 1,
          snapAlign: 'start',
        },
      },
    }),
  });
</script>

<style>
/* stylelint-disable selector-class-pattern */
:root {
  --vc-clr-primary: #748240;
  --vc-clr-secondary: #e5dfb9;
  --vc-pgn-height: 10px;
  --vc-pgn-width: 10px;
}

/* .carousel__track{
  margin-left:-250px!important;
} */

/* .carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

.carousel__pagination-button {
  padding: 0;
} */
</style>
