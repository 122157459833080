<template>
  <div>
    <Carousel
      :settings="settings"
      :breakpoints="breakpoints"
      :wrap-around="true"
    >
      <slide
        v-for="entry in entries"
        :key="entry"
      >
        <div class="flex flex-row ml-10">
          <div>
            <div class="border-r-2 border-gray-400 pr-5">
              <span class="block text-gray-500 text-6xl text-center">{{ entry.postDateDay }}</span>
              <span class="block text-gray-500 text-5xl text-center">{{ entry.postDateMonth }}</span>
              <span class="block text-gray-500 text-4xl text-center">{{ entry.postDateYear }}</span>
            </div>
          </div>
          <div class="px-8">
            <p>{{ entry.title }}</p>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="entry.blurb" />
            <a
              class="news-item__view-story"
              :href="entry.url"
            >View story</a>
          </div>
        </div>
      </slide>
      <template #addons>
        <navigation />
      </template>
    </Carousel>
  </div>
</template>

<script>
  import 'vue3-carousel/dist/carousel.css';
  import { defineComponent } from 'vue';
  import { Carousel, Slide, Navigation } from 'vue3-carousel';

  export default defineComponent({
    name: 'Vue3Carousel',
    components: {
      Carousel,
      Slide,
      Navigation,
    },
    props: {
      entries: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
      },
    }),
  });
</script>

<style>
/* stylelint-disable selector-class-pattern */
:root {
  --vc-clr-primary: #748240;
  --vc-clr-secondary: #e5dfb9;
  --vc-pgn-height: 10px;
  --vc-pgn-width: 10px;
}

.carousel__track {
  align-items: flex-start;
}

/* .carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

.carousel__pagination-button {
  padding: 0;
} */
</style>
